// Importing SCSS Variables
@import "../sass/variables.scss";
// Importing SCSS Mixins
@import "../sass/mixin.scss";
// Importing Font Faces
@import "../sass/font.scss";



// SCSS Starts From Here
*{
    margin: 0px;
    padding: 0px;
}

// Font and Bg Colors

.col-font{
    color: $HeadingColor;
}

.col-pri{
color: $PrimaryColor;
}

.col-sec{
    color: $SecondaryColor;
}

.bg-pri{
    background-color: $PrimaryColor;
}

.bg-pri2{
    background-color: #fafde3;
}

.bg-sec{
    background-color: $SecondaryColor;
}

// Font Families
.font-pri{
    font-family: $PrimaryFontFamily;
}

.font-pri-bold{
    font-family: $PrimaryBold;
}

.font-pri-extrabold{
    font-family: $PrimaryExtraBold;
}

.font-sec{
    font-family: $SecondaryFontFamily;   
}

.fs-25{
    font-size: 25px;
}

.fs-22{
    font-size: 22px;
}

.fs-20{
    font-size: 20px;
}

.fs-18{
    font-size: 18px;
}

.fs-16{
    font-size: 16px;
}

.fs-15{
    font-size: 15px;
}

.fs-14{
    font-size: 14px;
}

// Font Weight
.fw-300{
    font-weight: 300;
    }
    
    .fw-400{
    font-weight: 400;
    }
    
    .fw-500{
    font-weight: 500;
    }
    
    .fw-600{
    font-weight: 600;
    }
    
    .fw-700{
    font-weight: 700;
    }
    
    .fw-800{
    font-weight: 800;
    }
    
    .fw-900{
    font-weight: 900;
    }
// Hover on Element (With White Color Before Hover)
.hover-pri{
    @include HoverWhiteBefore;
    &:hover{
        color: $PrimaryColor;
    }
}

.hover-sec{
    @include HoverWhiteBefore;
    &:hover{
        color: $SecondaryColor;
    }
}

.hover-font{
    @include HoverWhiteBefore;
    &:hover{
        color: $HeadingColor;
    }
}

// Hover on Element (With Heading Color Before Hover)
.hover-pri-head{
    @include HoverHeadBefore;
    &:hover{
        color: $PrimaryColor;
    }
}

.hover-sec-head{
    @include HoverHeadBefore;
    &:hover{
        color: $SecondaryColor;
    }
}

.hover-font-head{
    @include HoverHeadBefore;
    &:hover{
        color: $HeadingColor;
    }
}

// Font Sizes

.font-banner{
    font-size: $BannerFontSize;
}

.font-sub-banner{
    font-size: $BannerSubFontSize;
}

.font-heading{
    font-size: $HeadingFontSize;
}

.font-sub-heading{
    font-size: $SubHeadingFontSize;
}

.font-para{
    font-size: $ParaFontSize ;
}

.font-quote{
    font-size: $FontQuote;
    color: $PrimaryColor;
}

// Button Customization
.btn-customization{
    color: white;
    background-color: $ButtonbgColor;
    padding: $ButtonPadding;
    border-radius: $ButtonRadius;
    font-family: $PrimaryFontFamily;
    text-transform: $TextTransform;
    font-weight: $ButtonWeight;
    border: none;

    &:hover{
        background-color: $ButtonHoverColor;
    }
}

.bg-btn-sec{
    background-color: $SecondaryColor;
}

// Button Font Size
.smlr-btn{
    font-size: $SmallerButtonFont !important;
}

.sm-btn{
    font-size: $SmallButtonFont;
}

.md-btn{
    font-size: $MediumButtonFont;
}

// .lg-btn{
//     font-size: $LargeButtonFont;
// }

// .lgr-btn{
//     font-size: $LargerButtonFont;
// }

    // Header
    .header-logo{
        width: 170px;
    }
    .header-logo-mb{
        width: 90px;
    }
    // Navbar
    .navbar-nav{
        .nav-link{
            color: #3e3e3e !important;
            font-weight: 600 !important;
            @include NavbarMenu;
    } 

    .hover1{
        @include NavbarMenu;
        display: none;
    }

    .right-menu{
       &:hover{
        .hover1{
            display: block;
        }
       } 
    }
    }

        .hover2{
            @include NavbarMenu;
            display: none;
        }
    
        .right-menu2{
           &:hover{
            .hover2{
                display: block;
            }
           } 
        }


        .dropstart:hover{
            .left-menu2{
                display: block !important;
            }
        }

        
        

    // Footer
    .footer-logo{
        width: 170px;
    }

    .bg-footer{
        background:$FooterBg;
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 180px;
        padding-bottom: 40px;
    }
    .footer-heading{
        font-size: $FooterHeading;
    }

    .footer-text{
        font-size: $FooterParagraph;
    }

    .hr-heading{
        @include BorderPriHrOpacity;
    }

    .hr-footer{
        @include BorderPriHrOpacity;
        width: 78px;
    }

    // Home Banner Customization
    .home-banner-text{
        position: absolute;
        top: 25%;
        left: 3%;

        & .st-hbt{
            font-size: 38px ;
        }

        & .t-hbt{
            font-size: 48px ;
        }
    }

    .hr-center{
        @include BorderPriHrOpacity;
        width: 120px;
        margin-left: auto;
        margin-right: auto;
    }
    
     .insurance-logo img{
        width: 180px;
    }

    // Upper Heading Font
    .font-upper-heading{
        font-size: 16px;  
    }

    // Two Images Two Texts Section
    .section-img-1{
        background-position: left;
        @include TwoImagesTwoTextsPosition(contain);
        background-attachment: fixed;
    }
    
    .section-img-2{
        background-position: right;
        @include TwoImagesTwoTextsPosition(contain);
        background-attachment: fixed;
    }

    .common-banner-text{
        position: absolute;
        top: 37%;
        left: 0%;
    }

    .ourvalues-banner{
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        height: max-content;
    }


    .w-diseases{
        width: 95px;
    }

    .w-icon{
        width: 60px;
    }

    .bg-services-middle{
        background: url(../images/ourservices/main/MiddleBannerBg.webp);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .text-justify{
        text-align: justify;
    }

    #more {
        display: none;
    }

// Media Screens

// Mobile
@media screen and (max-width: 575px){
    .col-ss-3{
        width: 80% !important;
      }
    #popup .box {
        z-index: 100 !important;
        position: relative;
        width: 88% !important;
        margin: 0 auto;
        height: auto;
        max-height: 70vh;
        margin-top: 55% !important;
        background: #fff;
        border-radius: 4px;
        padding: 3px;
        border: 1px solid #999;
        overflow: auto;
      }
       
      #popup .close-icon {
        content: 'x';
        cursor: pointer;
        position: fixed;
        right: 4% !important ;
        top: 25% !important;
        background: #ededed;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        color: #012a79 ;
        font-size: 20px;
      }
    
    .fs-25-page{
        font-size: 20px !important;
    }

    .navbar-nav{
        .nav-link{
            color: rgb(0, 0, 0) !important;
        }}
        
    .navbar-nav{
            .nav-link:hover{
                color: #9d0009 !important;
            }}
            
    .fs-15-disclaimer{
        font-size: 14px !important;
    }
    .fs-one-service{
        font-size: 20px;
    }

    .fs-one-service-para{
        font-size: 15px !important;
    }
    
    .font-doctor{
        font-size: 22px !important;
    }
    .section-img-1{
        background-position: left;
        @include TwoImagesTwoTextsPosition(contain);
        background-attachment: local;
    }
    
    .section-img-2{
        background-position: right;
        @include TwoImagesTwoTextsPosition(contain);
        background-attachment: local;
    }
    .middlecta-heading{
        font-size: 18px !important;
    }

    .middlecta-sub-heading{
        font-size: 18px !important;
    }

    .fs-18-providers{
        font-size: 18px !important; 
    }
    .fs-16-copyright{
        font-size: 15px !important;
    }
    .footer-heading{
        font-size: $MobileFooterHeading;
    }

    .lh-44-middle-banner{
        line-height: 35px !important;
    }

.fs-22-services{
    font-size: 18px !important;
}

.fs-16-services{
    font-size: 15px !important;
}
    .fs-18{
        font-size: 14px;
    }

            // Two Images Two Texts Section
            .section-img-1{
                background-position: unset;
                @include TwoImagesTwoTextsPosition(cover);
            }
    
            .section-img-2{
                background-position: unset;
                @include TwoImagesTwoTextsPosition(cover);
            }
    .font-banner{
        font-size: $MobileBannerFontSize;
    }
    
    .font-sub-banner{
        font-size: $MobileBannerSubFontSize;
    }
    
    .common-banner-text{
        position: absolute;
        top: 5%;
        left: 0%;
    }

    .footer-logo{
        width: 188px;
    }

    // Insurance Logo
    .insurance-logo img{
        width: 120px;
    }

    // Upper Heading Font
    .font-upper-heading{
        font-size: 13px;  
    }

    // Footer Background
    .bg-footer{
        background: #1d1f27 ;
        padding-top: 44px;
        padding-bottom: 44px;
    }

    // Font Sizes
    .font-heading{
        font-size: $HeadingFontSizeMobile;
    }
    
    .font-sub-heading{
        font-size: $SubHeadingFontSizeMobile;
    }
    
    .font-para{
        font-size: $ParaFontSizeMobile;
    }

    // Button Font Size
    .smlr-btn{
        font-size: $SmallerButtonFontMobile;
    }
    
    .sm-btn{
        font-size: $SmallButtonFontMobile;
    }
    
    .md-btn{
        font-size: $MediumButtonFontMobile;
    }
    // Home Banner Customization
    .home-banner-text{
        position: absolute;
        top: 20%;
        left: 4%;

        & .st-hbt{
            font-size: 15px ;
        }

        & .t-hbt{
            font-size: 22px ;
        }
    }
}
// Tablet
    @media screen and (min-width: 576px) and (max-width: 767px){
        .col-ss-3{
            width: 80% !important;
          }
        .navbar-nav{
            .nav-link:hover{
                color: #9d0009 !important;
            }}
            
        
    .navbar-nav{
        .nav-link{
            color: rgb(0, 0, 0) !important;
        }}
        .section-img-1{
            background-position: left;
            @include TwoImagesTwoTextsPosition(contain);
            background-attachment: local;
        }
        
        .section-img-2{
            background-position: right;
            @include TwoImagesTwoTextsPosition(contain);
            background-attachment: local;
        }
        .common-banner-text{
            position: absolute;
            top: 29%;
            left: 0%;
        }
        // Two Images Two Texts Section
        .section-img-1{
            background-position: unset;
            @include TwoImagesTwoTextsPosition(cover);
        }

        .section-img-2{
            background-position: unset;
            @include TwoImagesTwoTextsPosition(cover);
        }
            // Footer Background
    .bg-footer{
        background: #1d1f27 ;
        padding-top: 44px;
        padding-bottom: 44px;
    }

            // Home Banner Customization
    .home-banner-text{
        position: absolute;
        top: 17%;
        left: 4%;

        & h4{
            font-size: 20px ;
        }

        & h3{
            font-size: 30px ;
        }
    }
    
    // Button Font Size
    .sm-btn{
        font-size: $SmallButtonFontTablet;
    }
    
    .md-btn{
        font-size: $MediumButtonFontTablet;
    }
    }
    
    @media screen and (min-width: 768px) and (max-width: 991px){
        .col-ss-3{
            width: 45% !important;
          }
        .navbar-nav{
            .nav-link:hover{
                color: #9d0009 !important;
            }}
            
        
    .navbar-nav{
        .nav-link{
            color: rgb(0, 0, 0) !important;
        }}
        
    .common-banner-text{
            position: absolute;
            top: 35%;
            left: 0%;
        }
            // Two Images Two Texts Section
    .section-img-1{
        background-position: unset;
        @include TwoImagesTwoTextsPosition(cover);
    }
    
    .section-img-2{
        background-position: right;
        @include TwoImagesTwoTextsPosition(cover);
    }
            // Footer Background
    .bg-footer{
        background: #1d1f27 ;
        padding-top: 44px;
        padding-bottom: 44px;
    }

    // Home Banner Customization
    .home-banner-text{
        position: absolute;
        top: 21%;
        left: 4%;

        & h4{
            font-size: 30px ;
        }

        & h3{
            font-size: 40px ;
        }
    }
    
    // Button Font Size
    .sm-btn{
        font-size: $SmallButtonFont;
    }
    
    .md-btn{
        font-size: $MediumButtonFontTablet;
    }
    }

    @media screen and (min-width: 992px) and (max-width: 1199px){

        .navbar-nav{
            .nav-link{
                font-size: 15px;
            }
        }
        // Common Banner Font
        .font-banner{
            font-size: $TabletBannerFontSize;
        }
        
        .font-sub-banner{
            font-size: $TabletBannerSubFontSize;
        }

        .common-banner-text{
            position: absolute;
            top: 39%;
            left: 0%;
        }
        .home-banner-text{
            position: absolute;
            top: 27%;
            left: 4%;
    
            & h4{
                font-size: 30px ;
            }
    
            & h3{
                font-size: 40px ;
            }
        }
        
        // Button Font Size
        .sm-btn{
            font-size: $SmallButtonFont;
        }
        
        .md-btn{
            font-size: $MediumButtonFontTablet;
        }
        }

    // Insurance Logo
    .insurance-logo img{
        width: 120px;
    }



 

.btn-hover:hover{
    background-color: $SecondaryColor !important;
    color: white !important;
}

.btn-hover-white:hover{
    background-color: white;
    color: black;
}



// Bootstrap Customization 
.shadow{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)!important;
}


.carousel-indicators button{
    background-color: black !important;
}

.left-menu2{
    position: absolute;
    top: 0px;
    right: 249px;
}


.wid-mb{
    width: 85%;
}

        // Navbar
        #navmobile .navbar-nav{
            .nav-link{
                color: rgb(0, 0, 0) !important;
                @include NavbarMenu;
        } }
        
    .fs-22{
        font-size: 18px;
    }

    .hover1 li {
            font-size: 15px !important;
    } 

    .border-top-menu{
        border-top: 3px solid #012a79 !important;
    }

    .fs-22-services{
        font-size: 22px;
    }

    .fs-16-services{
        font-size: 16px;
    }

    .bg-grey{
        background-color: #f6f6f6 !important;
    }

    .lh-44-middle-banner{
        line-height: 44px;
    }

    .fs-16-copyright{
        font-size: 16px;
    }

    .fs-18-providers{
        font-size: 18px; 
    }

    .middlecta-heading{
        font-size: 28px;
    }

    .middlecta-sub-heading{
        font-size: 25px;
    }

    .font-doctor{
        font-size: 25px;
    }

    .fs-one-service{
        font-size: 18px;
    }

    .fs-one-service-para{
        font-size: 16px;
    }

    .lh-cta{
        line-height: 28px;
    }

    .fs-15-disclaimer{
        font-size: 15px;
    }

    .dropdown{
        color: white !important;
    }

    .fs-25-page{
        font-size: 22px;
    }

   #website-nav .navbar-toggler:focus{
        outline: none;
        box-shadow: none;
    }

    /* Popup style */
#popup .popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    z-index: 100 !important;
    top: 0%;
    left: 0;
  }
   
  #popup .box {
    z-index: 100 !important;
    position: relative;
    width: 45%;
    margin: auto;
    height: auto;
    max-height: 74vh;
    margin-top: 12%;
    background: #fff;
    border-radius: 4px;
    padding: 3px;
    border: 1px solid #999;
    overflow: auto;
  }
   
  #popup .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: 26% ;
    top: 21%;
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    color: #012a79 ;
    font-size: 20px;
  }

  .col-ss-3{
    width: 30%;
  }
  
  .image-box {
    position: relative;
    margin: auto;
    overflow: hidden;
    width: 100%;
}

.image-box img {
    max-width: 100%;
    transition: all 0.3s;
    display: block;
    width: 100%;
    height: auto;
    transform: scale(1);
}

  .image-box:hover img {
    transform: scale(1.3);
}

.fs-ss-18{
    font-size: 18px;
}

.fs-ss-14{
    font-size: 14px;
}

.fs-ss-15{
    font-size: 15px;
}

.pointer{
    cursor: pointer;
}

.bg-middlebanner{
    background-image: url(../images/home/MiddleBanner.webp);
}